<template>
  <v-container class="mt-3 px-sm-10 px-0 elevation-0" fluid>
    <PageHeader
      class="mb-7"
    >
      <template #header>
        <img
          src="@/assets/plugins/dcc_plugin.png"
          alt="dcc logo"
          height="32"
        >
        DCC Import
      </template>
      <template #subheader>
        Import a file for the DCC plugin and view your active and previous imports
      </template>
    </PageHeader>

    <v-row v-if="hasWritePermission">
      <v-col>
        <v-row class="pa-0 py-4 flex-nowrap">
          <v-col cols="12">
            <div class="import-headline py-2">
              Import File
            </div>
            <div class="import-subheadline pb-0">
              The file provided must be a CSV. To import successfully, please
              <a href="#" @click="downloadTemplate">download our template</a>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex justify-start flex-wrap pt-2">
            <v-btn
              class="custom-button custom-button--blue px-11 mr-sm-5 mb-4 mb-sm-0"
              style="min-width: 150px"
              height="34px"
              depressed
              :block="$vuetify.breakpoint.xsOnly"
              @click="selectFile"
            >
              {{ uploadFileName ? `File: ${uploadFileName}` : "Choose File" }}
            </v-btn>

            <input
              ref="fileUpload"
              type="file"
              class="d-none"
              accept=".csv"
              @change="onFileSelected"
            >
          </v-col>
        </v-row>

        <v-divider class="mt-5 mb-6" />

        <v-row class="pa-0 flex-wrap">
          <v-col
            cols="12"
            class="d-flex justify-center justify-sm-end align-center flex-wrap"
          >
            <div v-if="uploadPending" class="add-contact-progress pr-sm-7">
              Processing file
            </div>
            <div
              v-else-if="uploadError"
              class="add-contact-progress add-contact-progress--error pr-sm-7"
            >
              The supplied file was invalid
            </div>
            <v-btn
              class="custom-button my-4 px-14"
              :class="uploadPending ? 'custom-button--gray' : 'custom-button--blue'"
              height="34px"
              depressed
              :block="$vuetify.breakpoint.xsOnly"
              @click="onImport"
            >
              Import
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div v-if="currentImports.length" class="field__header mt-10">
      Active Imports
    </div>
    <ImportsTable
      v-if="currentImports.length"
      :imports="currentImports"
      @canceled="handleCanceledImport"
    />

    <div class="field__header mt-10">
      Previous Imports
    </div>
    <ImportsTable
      :imports="previousImports"
      :for-current-imports="false"
    />
    <PaginationButtons
      :current-page-number="importsPageNumber"
      :current-page-size="importsPageSize"
      :count-total="importsTotal"
      :page-sizes="[25, 50, 100]"
      @next-page="getImportsNextPage"
      @prev-page="getImportsPrevPage"
      @change-page-size="importsChangePageSize"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import ImportsTable from "@/views/Imports/components/ImportsTable";
import PaginationButtons from "@/sharedComponents/pagination/PaginationButtons";

export default {
  name: "DccImportsView",
  metaInfo: {
    title: 'DCC Imports'
  },
  components: {
    PageHeader,
    ImportsTable,
    PaginationButtons,
  },
  data() {
    return {
      uploadPending: false,
      uploadError: false,
      uploadFileName: null,
      file: null,
      currentImports: [],
      previousImports: [],
      eventSource: null,
      completedStatuses: ['completed', 'canceled', 'failed'],
      previousStatuses: ['queued', 'preparing', 'analyzing', 'requires action', 'processing', 'finalizing'],
      importsPageNumber: 1,
      importsPageSize: 25,
      importsTotal: 0,
      hasWritePermission: false,
      importType: 'dcc_confirmation',
    };
  },
  async created() {
    await this.getPreviousImports();
    await this.getCurrentImports();

    this.hasWritePermission = this.$store.getters['user/hasWritePermission'];

    this.eventSource = await this.$store.dispatch('mercure/import_type_subscribe', this.importType);
    if (!this.eventSource) {
      return;
    }

    this.eventSource.onmessage = (msg) => {
      this.$store.dispatch('mercure/update_import_type_last_event', {
        importType: this.importType,
        lastEventId: msg.lastEventId
      });

      const parsedMessage = JSON.parse(msg.data);

      // only allowed type here
      if (parsedMessage.importType !== this.importType) {
        return;
      }

      const currentImportIndex = this.currentImports.findIndex(elem => elem.id === parsedMessage.id) ?? null;
      if (currentImportIndex === -1) {
        if (this.completedStatuses.includes(parsedMessage.status)) {
          this.getPreviousImports();
          return;
        }

        this.currentImports.unshift(parsedMessage);
        return;
      }

      if (this.completedStatuses.includes(parsedMessage.status)) {
        this.$delete(this.currentImports, currentImportIndex);
        this.getPreviousImports();

        this.$store.commit('snackbar/showMessage', {
          color: "success",
          content: `An import has completed. Please check the section below for the results.`
        })
        return;
      }

      this.$set(this.currentImports, currentImportIndex, parsedMessage);
    };
  },
  beforeDestroy() {
    if (!this.eventSource) {
      return;
    }
    this.$store.dispatch('mercure/unsubscribe', this.eventSource)
  },
  methods: {
    async getPreviousImports() {
      const resp = await this.$rest.imports.get_collection({
        limit: this.importsPageSize,
        page: this.importsPageNumber,
        importType: this.importType,
        status: this.completedStatuses,
        sort: ['updatedAt:desc']
      });
      this.previousImports = resp.data.items;
      this.importsTotal = resp.data.totalCount;
    },
    async getImportsNextPage() {
      this.importsPageNumber += 1;
      await this.getPreviousImports();
    },
    async getImportsPrevPage() {
      this.importsPageNumber -= 1;
      await this.getPreviousImports();
    },
    async importsChangePageSize(size) {
      this.importsPageSize = size;
      this.importsPageNumber = 1;
      await this.getPreviousImports();
    },
    async getCurrentImports() {
      const resp = await this.$rest.imports.get_collection({
        importType: this.importType,
        status: this.previousStatuses,
        sort: ['createdAt:desc']
      });
      this.currentImports = resp.data.items;
    },
    async handleCanceledImport() {
      await this.getCurrentImports();
      await this.getPreviousImports();
    },
    downloadTemplate() {
      window.location.replace(
        process.env.VUE_APP_REST_ADDRESS + `imports/${this.$store.getters["user/account"].accountId}/template/${this.importType}`
      );
    },
    selectFile() {
      this.$refs.fileUpload.click();
    },
    /**
     * On File Selected
     * @param {{target: {files: FileList}}} event
     */
    onFileSelected(event) {
      const file = event.target.files[0];
      this.file = file;
      this.uploadFileName = file.name;
    },
    async onImport() {
      if (!this.file) {
        return this.$store.commit('snackbar/showMessage', {
          'content': 'Please select a CSV file before importing.',
          'color': 'error',
        });
      }

      try {
        const formData = new FormData();
        formData.append('file', this.file);
        formData.append('importType', this.importType);

        const { data } = await this.$rest.imports.post_resource(formData);

        this.postUploadStatusHandler(data);
      } catch (e) {
        const errorResponse = e.response?.data ?? null;
        if (errorResponse) {
          const firstError = errorResponse.errors?.[0] ?? null;

          if (firstError) {
            return this.$store.commit('snackbar/showMessage', {
              'content': firstError.error,
              'color': 'error',
            });
          }
        }

        return this.$store.commit('snackbar/showMessage', {
          'content': 'Error while trying to upload the file, please try again.',
          'color': 'error',
        });
      }
    },
    /**
     * After uploaded file, handle some specific cases based on the message
     * @param data
     * @returns {*}
     */
    postUploadStatusHandler(data) {
      if (data.status === 'failed') {
        return this.$store.commit('snackbar/showMessage', {
          'content': 'Failed to process this file. Please try again.',
          'color': 'error',
        });
      }

      this.success_message = 'Your file has been added into the Queue.';
      this.success_dialog = true;

      this.file = null;
      this.uploadFileName = null;
      this.$refs.fileUpload.value = null;

      this.getCurrentImports();
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-hint {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #66788e;
}

.field {
  &__header {
    margin-bottom: 25px;
  }
}
</style>
